import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import Button from "../components/Button"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

export default props => {
  const {
    richArticleText,
    mainImage,
    title,
    related,
    author,
    publishDate,
    metaDescription,
    seoKeywords,
  } = props.data.item

  const image = getImage(mainImage)
  const output = renderRichText(richArticleText)

  return (
    <Layout>
      <SEO 
        title={title}
        description={metaDescription}
        seoKeywords={seoKeywords}
        publicURL={mainImage.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
            <div className="w-full lg:w-2/3 pb-8">
              <GatsbyImage image={image} alt={title} />
            </div>
            <div className="w-full lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-mnblue sm:text-4xl mb-1">
                {title}
              </h1>
              <h2 className="pt-4 text-2xl leading-tight font-serif tracking-tight text-engred">
                {author}
              </h2>
              <p className="pt-0 mt-0 lg: mb-8 text-sm leading-tight tracking-tight text-pacblue">
                {publishDate}
              </p>
              {output && (
                <div className="my-4 text-base text-mnblue whitespace-pre-line">
                  {output}
                </div>
              )}
              {/*  {url && (
                <div className="mt-8">
                  <Button href={url}>More info</Button>
                </div>
              )} */}
              <p className="pt-0 mt-0 lg: mb-8 text-sm leading-tight tracking-tight text-engred">
                {seoKeywords}
              </p>
            </div>
        </div>
      </div>
      {related && (
        <div className="bg-pacblue-light py-12 lg:py-16">
          <div className="container">
            {/*<h2 className="text-3xl sm:text-4xl leading-tight font-extrabold tracking-tight text-mnblue mb-8">
              Check out some of our other blog stories.
            </h2>*/}
          </div>
          <Cards items={related} hideLastItemOnMobile={true} />
        </div>
      )}
      {/*<Newsletter />*/}
    </Layout>
  )
}

export const query = graphql`
  query ArticleItemQUery($slug: String!) {
    item: contentfulArticle(slug: { eq: $slug }) {
      richArticleText {
        raw
      }
      mainImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
        localFile {
          publicURL
        }
      }
      title
      related {
        ...ArticleCard
      }
      thumbText
      thumbImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      } 
      metaDescription
      seoKeywords
      author
      publishDate(formatString: "MMMM DD, YYYY")
    }
  }
`